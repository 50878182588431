import React from 'react'
import contactImg from "./images/contact-us/1.png"
import contactImg2 from "./images/contact-us/2.png"
import contactImg3 from "./images/contact-us/3.png"

const contact = () => {
  return (
    <>
        <div class="aon-contact-area">
            <div class="container">
                <div class="section-head text-center">

                    <h2 class="sf-title">Contact Information</h2>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do usmod tempor incididunt ut labore et dolore magna aliqua.</p>

                </div>
                <div class="section-content">
                    <div class="sf-contact-info-wrap">  
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="sf-contact-info-box">
                                    <div class="sf-contact-icon">
                                        <span><img src={contactImg} alt="" /></span>
                                    </div>
                                    <div class="sf-contact-info">
                                        <h4 class="sf-title">Mailing Address</h4>
                                        <p>121 King Street, Melbourne 
                                            Victoria 3000 Australia</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="sf-contact-info-box">
                                    <div class="sf-contact-icon">
                                        <span><img src={contactImg2} alt="" /></span>
                                    </div>
                                    <div class="sf-contact-info">
                                        <h4 class="sf-title">Email Info</h4>
                                        <p>info@servicefinder.com</p><p>support@servicefinder.com</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="sf-contact-info-box">
                                    <div class="sf-contact-icon">
                                        <span>
                                            <img src={contactImg3} alt="" /> 
                                            </span>
                                    </div>
                                    <div class="sf-contact-info">
                                        <h4 class="sf-title">Phone Number</h4>
                                        <p>0800-123456 (24/7 Support Line)</p>
                                        <p>0800-123654</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sf-contact-form-wrap">
                        <div class="sf-contact-form">
                            <div class="sf-con-form-title text-center">
                                <h2 class="m-b30">Contact Information</h2>
                            </div>
                            <form class="contact-form" method="post">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" name="fullname" placeholder="Name" class="form-control" required="" />
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" name="email" placeholder="Email" class="form-control" required="" /> 
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" name="phone" placeholder="Phone" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" name="subject" placeholder="Subject" class="form-control" required="" />
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <textarea name="message" placeholder="Message" class="form-control" required=""></textarea>
                                        </div>
                                    </div>
                        
                                    <div class="col-md-12">
                                        <div class="g-recaptcha" data-sitekey="6LfcCr0eAAAAAN2P-3cJJC1StgxbUWvwELbMVjnp"></div>
                                    </div>                                              
                                </div>
                                <div class="sf-contact-submit-btn">
                                    <button class="site-button" type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>              
                </div>
            </div>
        </div>
    </>
  )
}

export default contact