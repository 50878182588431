import React from 'react'
import whyChoose from "./images/whychoose/1.png"
import whyChoose2 from "./images/whychoose/2.png"
import whyChoose3 from "./images/whychoose/3.png"

const why = () => {
  return (
    <section class="aon-why-choose2-area" id='service'>
        <div class="container">

            <div class="aon-why-choose2-box">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="aon-why-choose-info">
                            <div class="section-head">
                                <span class="aon-sub-title">Choose</span>
                                <h2 class="aon-title">Why Choose us</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>

                            <ul class="aon-why-choose-steps list-unstyled">
                                <li class="d-flex">
                                    <div class="aon-w-choose-left aon-icon-effect">
                                        <div class="aon-w-choose-icon">
                                            <i class="aon-icon">
                                                <img src={whyChoose} alt="" />
                                            </i>
                                        </div>
                                    </div>
                                    <div class="aon-w-choose-right">
                                        <h4 class="aon-title">Meet new customers</h4>
                                        <p>Suspendisse tincidunt rutrum ante. Vestibulum elementum ipsum sit amet turpis elementum lobortis.</p>
                                    </div>
                                </li>
                                <li class="d-flex">
                                    <div class="aon-w-choose-left aon-icon-effect">
                                        <div class="aon-w-choose-icon">
                                            <i class="aon-icon">
                                                <img src={whyChoose2} alt="" />
                                            </i>
                                        </div>
                                    </div>
                                    <div class="aon-w-choose-right">
                                        <h4 class="aon-title">Grow your revenue</h4>
                                        <p>Suspendisse tincidunt rutrum ante. Vestibulum elementum ipsum sit amet turpis elementum lobortis.</p>
                                    </div>
                                </li>
                                <li class="d-flex">
                                    <div class="aon-w-choose-left aon-icon-effect">
                                        <div class="aon-w-choose-icon">
                                            <i class="aon-icon">
                                                <img src={whyChoose3} alt="" />
                                            </i>
                                        </div>
                                    </div>
                                    <div class="aon-w-choose-right">
                                        <h4 class="aon-title">Build your online reputation</h4>
                                        <p>Suspendisse tincidunt rutrum ante. Vestibulum elementum ipsum sit amet turpis elementum lobortis.</p>
                                    </div>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="aon-why-choose2-line">
                            <div class="aon-why-choose2-pic"></div>
                        </div>     
                </div>
                </div>
            </div>
            
        </div>
    </section>
  )
}

export default why