import React from 'react'
import Header from './header.js'
import Banner from './banner.js'
import About from './about.js'
import Why from './why'
import Category from './categories'
import How from './how'
import Contact from './contact'
import RequestService from './book'
import Footer from './footer'

const home = () => {
  return (
    <>
      <div className="page-wraper">
        <Header />   
        <div class="page-content right">
          <Banner />
          <About />
          <hr />
          <Why />
          <Category />
          <How />
          <Contact />
        </div>    
        <RequestService />
        <Footer />
      </div>
    </>
  )
}

export default home