import React from 'react'
import LightLogo from './images/logo-light.png'
import DarkLogo from './images/logo-dark.png'

const header = () => {
  return (
    <>
         <header className="site-header header-style-1 mobile-sider-drawer-menu header-full-width ">
            <div className="sticky-header main-bar-wraper  navbar-expand-lg">
                <div className="main-bar">  
                                    
                    <div className="container clearfix">
                        <div className="logo-header">
                            <div className="logo-header-inner logo-header-one">
                                <a href="javascript:;" onclick="myfun('home')">
                                    <img src={LightLogo} alt="" className="site-logo-has" />
                                    <img src={DarkLogo} alt="" className="site-logo-sticky" />
                                </a>
                            </div>
                        </div>  
                        <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button" className="navbar-toggler collapsed">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar icon-bar-first"></span>
                            <span className="icon-bar icon-bar-two"></span>
                            <span className="icon-bar icon-bar-three"></span>
                        </button> 

                        <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-start left">
                            
                            <ul className=" nav navbar-nav">
                                <li className=" current-menu-item" onclick="myfun('home')"><a href="javascript:;">Home</a></li>

                                <li className="" onclick="myfun('about')"> <a href="javascript:;">About Us</a> </li>

                                <li className="" onclick="myfun('categories')"><a href="javascript:;">Categories</a> </li>
                        
                                <li className="" onclick="myfun('services')"><a href="javascript:;">Services</a></li>
                                                    
                                <li onclick="myfun('contact')"><a href="javascript:;">Contact</a></li>  

                            </ul>
                        </div>                                                           
                        <div class="extra-nav header-2-nav">
                            <div class="extra-cell">
                                <button type="button" onclick="myfun('hire')" class="site-button aon-btn-login" data-toggle="modal">
                                    Hire Now
                                </button>
                            </div>
                        </div>
                    </div>    
                
                </div>
            </div>
        </header>
    </>
  )
}

export default header