import React from 'react'
import darkLogo from "./images/logo-dark.png"
const footer = () => {
  return (
    <div>
        <footer class="site-footer footer-light">
            
            <div class="footer-top-newsletter">
                <div class="container">
                    <div class="sf-news-letter">
                        <span>Subscribe Our Newsletter</span>
                        <form>
                            <div class="form-group sf-news-l-form">
                                <input type="text" class="form-control" placeholder="Enter Your Email"  />
                                <button type="submit" class="sf-sb-btn">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-6  m-b30">
                            <div class="sf-site-link sf-widget-link">
                                <h4 class="sf-f-title">Site Links</h4>
                                <ul>
                                    <li><a href="#">Home</a></li>
                                    <li><a href="#about">About Us</a></li>
                                    <li><a href="#contact-us">Contact Us</a></li>
                                    <li><a href="#services">Services</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6  m-b30">
                            <div class="sf-site-link sf-widget-cities">
                                <h4 class="sf-f-title">Popular Cities</h4>
                                <ul>
                                    <li><a href="javascript:;">Ballston Lake</a></li>
                                    <li><a href="javascript:;">Batumi</a></li>
                                    <li><a href="javascript:;">Brooklyn</a></li>
                                    <li><a href="javascript:;">Cambridge</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6  m-b30">
                            <div class="sf-site-link sf-widget-categories">
                                <h4 class="sf-f-title">Categories</h4>
                                <ul>
                                    <li><a href="javascript:;">Car Service</a></li>
                                    <li><a href="javascript:;">House Cleaning</a></li>
                                    <li><a href="javascript:;">Transport</a></li>
                                    <li><a href="javascript:;">Yoga Classes</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6  m-b30">
                            <div class="sf-site-link sf-widget-contact">
                                <h4 class="sf-f-title">Contact Info</h4>
                                <ul>
                                    <li>Greater Lowel</li>
                                    <li>+41 232 525 5257</li>
                                    <li>+41 856 525 5369</li>
                                    <li>hello@servicefinder.com</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">
                    <div class="sf-footer-bottom-section">
                        <div class="sf-f-logo">
                            <a href="javascript:void(0);">
                                <img src={darkLogo} alt="" />
                            </a>
                        </div>
                    	<div class="sf-f-copyright">
                        	<span>Copyright 2023. All Rights Reserved</span>
                        </div>
                    </div>
                </div>   
            </div>   
    
        </footer>
    </div>
  )
}

export default footer