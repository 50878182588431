import React from 'react'
import {
    BrowserRouter,
    Switch,
    Route,
    Link,
    useRouteMatch,
  } from "react-router-dom";  
import errorPage from "./images/ERROR.png"

const notFound = () => {
  return (
    <>
        <div class="section-full page-notfound-outer p-t120 p-b90">
            <div class="container">
                <div class="section-content">

                    <div class="page-notfound">
                        <div class="page-notfound-media">
                            <img src={errorPage} alt="" />
                        </div>
                        <div class="page-notfound-content m-b30">
                            <h3 class="error-comment">The Page You Are Looking For Doesn't Exist...</h3>
                            <p>We Ran Into An Issue, But Don’t Worry, We’ll Take Care Of It For Sure.</p>
                            <Link to="/" class="site-button">Back to Home</Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>
  )
}

export default notFound