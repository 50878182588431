import React from 'react'

const book = () => {
  return (
    <>
        <div class="aon-contact-area">
            <div class="container">
                <div class="section-head text-center">

                    <h2 class="sf-title">REQUEST A SERVICE</h2>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do usmod tempor incididunt ut labore et dolore magna aliqua.</p>

                </div>
                <div class="section-content">
                    <div id="aon-provider-Req-quote" class="sf-provi-qoute-box cleafix m-b50 sf-provi-fullBox">
                        <h3 class="sf-provi-title">Request Form</h3>
                        <div class="sf-divider-line"></div>                
                        <div class="sf-provi-qform">
                            <form>
                                <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input name="name" type="text" required="" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input name="email" type="text" required="" class="form-control" />
                                </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Phone</label>
                                        <input name="phone" type="text" required="" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Service</label>
                                        <select name="phone" required="" class="form-control">
                                            <option>Choose a Service</option>
                                            <option value={"Car Service"}>Car Service</option>
                                            <option value={"Salon Service"}>Salon Service</option>
                                            <option value={"Electrician"}>Electrician</option>
                                            <option value={"Yoga Classes"}>Yoga Classes</option>
                                            <option value={"House Cleaning"}>House Cleaning</option>
                                            <option value={"Gym Classes"}>Gym Classes</option>
                                            <option value={"Yoga Classes"}>Yoga Classes</option>
                                            <option value={"AC Repair"}>AC Repair</option>
                                            <option value={"Transport"}>Transport</option>
                                            <option value={"Plumber"}>Plumber</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                <div class="form-group">
                                    <label>Message</label>
                                    <textarea name="message" rows="8" class="form-control"></textarea>
                                </div>
                                </div>
                                <div class="col-md-12">
                                <div class="form-group text-center qout-submit-btn">
                                    <input type="submit" value="Request Now" class="site-button" />
                                </div>
                                </div>
                                </div>
                            </form>
                        </div>
                        
                        
                    </div>            
                </div>
            </div>
        </div>
    </>
  )
}

export default book