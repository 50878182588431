import React from 'react'
import stepIcon1 from "./images/step-icon/1.png"
import stepIcon2 from "./images/step-icon/2.png"
import stepIcon3 from "./images/step-icon/3.png"

const how = () => {
  return (
    <section class="aon-howit-area2">
        <div class="container">

            <div class="aon-howit-area2-section">
                <div class="aon-howit-area2-bg">
                    <div class="section-head aon-title-center white">
                        <span class="aon-sub-title">Stpes</span>
                        <h2 class="sf-title">How It Work</h2>
                    </div>
                    <div class="section-content">
                        <div class="aon-categories-area2-section">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-md-6">
                                    <div class="aon-howit-area2-iconbox aon-icon-effect">
                                        <div class="aone-howit-number">01</div>
                                        <div class="aon-howit-area2-icon">
                                            <span>
                                                <i class="aon-icon"><img src={stepIcon1} alt="" /></i>
                                            </span>
                                        </div>
                                        <div class="aon-howit-area2-content">
                                            <h4 class="aon-tilte">Describe Your Task</h4>
                                            <p>This helps us determine which Taskers We are abest jobs.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="aon-howit-area2-iconbox aon-howit-arrow aon-icon-effect">
                                        <div class="aone-howit-number">02</div>
                                        <div class="aon-howit-area2-icon">
                                            <span>
                                                <i class="aon-icon"><img src={stepIcon2} alt="" /></i>
                                            </span>
                                        </div>
                                        <div class="aon-howit-area2-content">
                                            <h4 class="aon-tilte">Choose a Tasker</h4>
                                            <p>This helps us determine which Taskers We are abest jobs.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="aon-howit-area2-iconbox aon-icon-effect">
                                        <div class="aone-howit-number">03</div>
                                        <div class="aon-howit-area2-icon">
                                            <span>
                                                <i class="aon-icon">
                                                    <img src={stepIcon3} alt="" /></i>
                                            </span>
                                        </div>
                                        <div class="aon-howit-area2-content">
                                            <h4 class="aon-tilte">Live Smarter</h4>
                                            <p>This helps us determine which Taskers We are abest jobs.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
  )
}

export default how