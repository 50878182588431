import React from 'react'
import aboutPic from "./images/about-pic.jpg"

const about = () => {
  return (
    <>
         <div className="aon-about-area" id='about'>
            <div className="container">

                <div className="section-content">
                    <div className="row d-flex flex-wrap align-items-center a-b-none">
                        <div className="col-lg-6 col-md-12">
                            <div className="aon-about-pic">
                                <img src={aboutPic} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="section-head">
                                <span className="aon-sub-title">About</span>
                                <h2 className="sf-title">Upgrade Your Skills With Service Finder</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravidem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravidal abore et dolore magna aliqua. </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>
  )
}

export default about