import React from 'react'
import categoryIcon1 from "./images/popular-categories-icon/1.png"
import categoryIcon2 from "./images/popular-categories-icon/2.png"
import categoryIcon3 from "./images/popular-categories-icon/3.png"
import categoryIcon4 from "./images/popular-categories-icon/4.png"
import categoryIcon5 from "./images/popular-categories-icon/5.png"
import categoryIcon6 from "./images/popular-categories-icon/6.png"
import categoryIcon7 from "./images/popular-categories-icon/7.png"
import categoryIcon8 from "./images/popular-categories-icon/8.png"
import categoryIcon9 from "./images/popular-categories-icon/9.png"


const categories = () => {
    return (
        <section class="aon-why-choose2-area">
            <div class="container">
                <div class="section-head">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <span class="aon-sub-title">categories</span>
                            <h2 class="sf-title">Popular Categories</h2>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do usmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
                    
                <div class="section-content">
                    <div class="aon-categories-area2-section">
                        <div class="row justify-content-center">
                            <div class="col-lg-4 col-md-6">
                                <div class="media-bg-animate mba-bdr-15">
                                    <div class="aon-categories-area2-iconbox aon-icon-effect">
                                        <div class="aon-cate-area2-icon">
                                            <span>
                                                <i class="aon-icon">
                                                    <img src={categoryIcon1} alt="" />
                                                </i>
                                            </span>
                                        </div>
                                        <div class="aon-cate-area2-content">
                                            <h4 class="aon-tilte"><a href="categories-detail.html">Car Service</a></h4>
                                            {/* <p>124 Listing</p> */}
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="media-bg-animate mba-bdr-15">
                                    <div class="aon-categories-area2-iconbox aon-icon-effect">
                                        <div class="aon-cate-area2-icon">
                                            <span> 
                                                <i class="aon-icon"><img src={categoryIcon2} alt="" /></i>
                                            </span>
                                        </div>
                                        <div class="aon-cate-area2-content">
                                            <h4 class="aon-tilte"><a href="categories-detail.html">Salon Services</a></h4>
                                            {/* <p>132 Listing</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="media-bg-animate mba-bdr-15">
                                    <div class="aon-categories-area2-iconbox aon-icon-effect">
                                        <div class="aon-cate-area2-icon">
                                            <span>
                                                <i class="aon-icon"><img src={categoryIcon3} alt="" /></i>
                                            </span>
                                        </div>
                                        <div class="aon-cate-area2-content">
                                            <h4 class="aon-tilte"><a href="categories-detail.html">Plumber</a></h4>
                                            {/* <p>124 Listing</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="media-bg-animate">
                                    <div class="aon-categories-area2-iconbox aon-icon-effect">
                                        <div class="aon-cate-area2-icon">
                                            <span>
                                                <i class="aon-icon"><img src={categoryIcon4} alt="" /></i>
                                            </span>
                                        </div>
                                        <div class="aon-cate-area2-content">
                                            <h4 class="aon-tilte"><a href="categories-detail.html">Electrician</a></h4>
                                            {/* <p>128 Listing</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="media-bg-animate mba-bdr-15">
                                    <div class="aon-categories-area2-iconbox aon-icon-effect">
                                        <div class="aon-cate-area2-icon">
                                            <span>
                                                <i class="aon-icon"><img src={categoryIcon5} alt="" /></i>
                                            </span>
                                        </div>
                                        <div class="aon-cate-area2-content">
                                            <h4 class="aon-tilte"><a href="categories-detail.html">Yoga Classes</a></h4>
                                            {/* <p>145 Listing</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="media-bg-animate">
                                    <div class="aon-categories-area2-iconbox aon-icon-effect">
                                        <div class="aon-cate-area2-icon">
                                            <span>
                                                <i class="aon-icon"><img src={categoryIcon6} alt="" /></i>
                                            </span>
                                        </div>
                                        <div class="aon-cate-area2-content">
                                            <h4 class="aon-tilte"><a href="categories-detail.html">House Ceaning</a></h4>
                                            {/* <p>165 Listing</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="media-bg-animate mba-bdr-15">
                                    <div class="aon-categories-area2-iconbox aon-icon-effect">
                                        <div class="aon-cate-area2-icon">
                                            <span>
                                                <i class="aon-icon"><img src={categoryIcon7} alt="" /></i>
                                            </span>
                                        </div>
                                        <div class="aon-cate-area2-content">
                                            <h4 class="aon-tilte"><a href="categories-detail.html">Gym Classes</a></h4>
                                            {/* <p>156 Listing</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="media-bg-animate mba-bdr-15">
                                    <div class="aon-categories-area2-iconbox aon-icon-effect">
                                        <div class="aon-cate-area2-icon">
                                            <span>
                                                <i class="aon-icon">
                                                    <img src={categoryIcon8} alt="" /></i>
                                            </span>
                                        </div>
                                        <div class="aon-cate-area2-content">
                                            <h4 class="aon-tilte"><a href="categories-detail.html">Transport</a></h4>
                                            {/* <p>149 Listing</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="media-bg-animate mba-bdr-15">
                                    <div class="aon-categories-area2-iconbox aon-icon-effect">
                                        <div class="aon-cate-area2-icon">
                                            <span>
                                                <i class="aon-icon">
                                                    <img src={categoryIcon9 } alt="" /></i>
                                            </span>
                                        </div>
                                        <div class="aon-cate-area2-content">
                                            <h4 class="aon-tilte"><a href="categories-detail.html">AC Repair</a></h4>
                                            {/* <p>175 Listing</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default categories