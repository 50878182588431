import React from 'react'
import ImgBanner from './images/banner/bnr-pic.jpg'
import lineRight from "./images/banner2/line-right.png"
import imgBanner2 from "./images/banner2/1.png"
import lineLeft from "./images/banner2/line-left.png"

const banner = () => {
  return (
    <section className="aon-banner-wrap" id='home'>
        <div className="aon-banner-outer sf-overlay-wrapper">
            <div className="aon-banner-pic">
                <div className="aon-curve-area"></div>
                <div className="aon-overlay-main" style={{ opacity: '0.85', backgroundColor: '#022278' }}></div>
                <img src={ImgBanner} width="1919" height="976" alt="" />
            </div>
            <div className="aon-banner-text">
                <div className="container"> 
                    <div className="aon-bnr-write">
                        <h2 className="text-top-line"> Seek Experts, <span className="text-secondry">Hire </span><span className="text-secondry">Experts</span> & </h2>
                        <h2 className="text-bot-line">Get That Job Done</h2>
                    </div>
                </div>
            </div>
        </div>
        <div className="aon-find-bar aon-findBar-vertical">
            <div className="container">
                <div class="aon-bnr2-media-wrap">
                    <div class="aon-bnr2-media">
                        <img src={ imgBanner2 } alt="" />
                    </div>
                    <div class="aon-bnr2-lines-left">
                        <div class="aon-bnr2-line-left-content">
                            <img src={lineLeft} alt="" />
                            <span class="circle-l-1 slide-fwd-center"></span>
                            <span class="circle-l-2 slide-fwd-center2"></span>
                            <span class="circle-l-3 slide-fwd-center3"></span>
                        </div>
                    </div>
                    <div class="aon-bnr2-lines-right">
                        <img src={lineRight} alt="" />
                        <span class="circle-r-1 slide-fwd-center3"></span>
                        <span class="circle-r-2 slide-fwd-center2"></span>
                        <span class="circle-r-3 slide-fwd-center"></span>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default banner